import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from './styled'

const SeloMec = () => {
  const { seloMec } = useStaticQuery(
    graphql`
      query {
        seloMec: file(relativePath: { eq: "selo-mec.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  )

  return <S.LogoWrapper fluid={seloMec.childImageSharp.fluid} className="foo" />
}

export default SeloMec